@charset "UTF-8";

@import "reset"; //リセットCSS

$breakpoint: 700px;
$headerH: 105px;
$headerH_pc: 140px;

@mixin mq-for-wideScreen-up {
  @media (min-width: $breakpoint) {
    @content;
  }
}

body {
  background-color: #0b1728;
  position: relative;
  margin: 0;
  font-size: 16px;
  font-family: "vdl-logog", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
}

a {
  &:hover {
    opacity: 0.7;
  }
}

.sp {
  display: block;
  @include mq-for-wideScreen-up {
    display: none;
  }
}
.pc {
  display: none;
  @include mq-for-wideScreen-up {
    display: block;
  }
}

#root {
  position: relative;
  min-height: 100vh;
  margin: 0;
}

header {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: $headerH;
  @include mq-for-wideScreen-up {
    height: $headerH_pc;
  }
  margin: 0 auto;
  padding: 0;
  background: #0b1728;
  border-bottom: solid 2px #ec3ff5;
  z-index: 1000;
  .logo_wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: $breakpoint;
    margin: 0 auto;
    padding: 0 2vw;
    @include mq-for-wideScreen-up {
      padding: 0;
    }
    img {
      width: 100%;
      max-width: 400px;
    }
    button {
      width: 45vw;
      max-width: 100px;
      border: solid 1px #00ffff;
      border-radius: 30px;
      margin-left: 10px;
      padding: 6px 0 4px;
      text-align: center;
      color: #00ffff;
      font-size: 0.8rem;
      line-height: 100%;
      &:hover {
        opacity: 0.7;
      }
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: $breakpoint;
    margin: 10px auto 0;
    a {
      width: 100%;
      padding: 10px 0;
      border-bottom: solid 3px transparent;
      text-align: center;
      &:hover,
      &.active {
        border-bottom: solid 3px #ec3ff5;
      }
    }
  }
  &.not_login {
    justify-content: center;
    height: 75px;
    @include mq-for-wideScreen-up {
      height: 80px;
    }
    .logo_wrap {
      display: block;
      text-align: center;
    }
  }
  &.lp {
    position: relative;
    display: block;
    margin: 0 0 100px 0;
    padding: 0;
    height: auto;
    text-align: center;
    background-image: url("../../public/images/lp_header_bg.png");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center;
    border-bottom: none;
    @include mq-for-wideScreen-up {
      margin: 0;
    }
    img {
      width: 100%;
      max-width: 700px;
      margin: 10px auto;
    }
    p {
      margin: 10px auto;
      text-align: center;
      font-size: 1rem;
    }
    a.btn_float_banner {
      position: absolute;
      bottom: -140px;
      left: calc(50% - 155px);
      width: 80%;
      @include mq-for-wideScreen-up {
        bottom: 4px;
        left: 20px;
        width: 310px;
        height: 109px;
        box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
      }
      img {
        margin: 0;
      }
    }
  }
}

.foot_wrap {
  height: 280px;
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 70%;
  max-width: calc($breakpoint - 15%);
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  border-top: solid 3px #ec3ff5;
  .sns_share_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 20px;
    padding: 0;
    .caption {
      margin-right: 10px;
      padding: 5px 10px;
      border-bottom: solid 1px #fff;
      text-align: center;
      color: #fff;
      font-size: 85%;
      font-weight: bold;
    }
    a {
      margin: 0 5px;
      line-height: 100%;
      &:hover {
        opacity: 0.7;
      }
      svg {
        color: #32ffff !important;
        font-size: 2rem;
      }
      &:last-of-type {
        svg {
          font-size: 1.75rem;
        }
      }
    }
  }
  nav {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    a {
      padding: 0;
      text-align: center;
      &:hover {
        opacity: 0.7;
      }
      &.blue {
        color: #32ffff;
      }
    }
  }
  p {
    margin: 20px auto;
    text-align: center;
    font-size: 0.85rem;
  }
}

.wrap {
  display: block;
  position: relative;
  width: 100%;
  max-width: $breakpoint;
  margin: 0 auto;
  padding: 145px 3vw 0;
  text-align: center;
  @include mq-for-wideScreen-up {
    padding: 180px 0 0;
  }
  &.not_login {
    padding: 125px 3vw 0;
    @include mq-for-wideScreen-up {
      padding: 130px 0 0;
    }
  }
  &.lp {
    padding: 30px 3vw 0;
    @include mq-for-wideScreen-up {
      padding: 30px 0 0;
    }
  }
}

// ローディング
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  width: 100%;
  height: 200px;
  text-align: center;
  overflow: hidden;
  .loader {
    font-size: 1rem;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: loadanimation_w 1.1s infinite ease;
    animation: loadanimation_w 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@-webkit-keyframes loadanimation_w {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
      2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
      0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
      -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes loadanimation_w {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff,
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7),
      2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7),
      1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7),
      0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7),
      -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7),
      -1.8em -1.8em 0 0em #ffffff;
  }
}
@-webkit-keyframes loadanimation_b {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #000000,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #000000,
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7),
      2.5em 0em 0 0em #000000,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em #000000,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7),
      0em 2.5em 0 0em #000000,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #000000,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7),
      -2.6em 0em 0 0em #000000,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #000000;
  }
}
@keyframes loadanimation_b {
  0%,
  100% {
    box-shadow:
      0em -2.6em 0em 0em #000000,
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7);
  }
  12.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.7),
      1.8em -1.8em 0 0em #000000,
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5);
  }
  25% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.5),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.7),
      2.5em 0em 0 0em #000000,
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  37.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.5),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.7),
      1.75em 1.75em 0 0em #000000,
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  50% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.5),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.7),
      0em 2.5em 0 0em #000000,
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.2),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  62.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.5),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em 1.8em 0 0em #000000,
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  75% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.5),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.7),
      -2.6em 0em 0 0em #000000,
      -1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2);
  }
  87.5% {
    box-shadow:
      0em -2.6em 0em 0em rgba(0, 0, 0, 0.2),
      1.8em -1.8em 0 0em rgba(0, 0, 0, 0.2),
      2.5em 0em 0 0em rgba(0, 0, 0, 0.2),
      1.75em 1.75em 0 0em rgba(0, 0, 0, 0.2),
      0em 2.5em 0 0em rgba(0, 0, 0, 0.2),
      -1.8em 1.8em 0 0em rgba(0, 0, 0, 0.5),
      -2.6em 0em 0 0em rgba(0, 0, 0, 0.7),
      -1.8em -1.8em 0 0em #000000;
  }
}

.mxauto {
  margin: 0 auto;
}
.mt10 {
  margin-top: 10px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mt50 {
  margin-top: 50px !important;
}

.w100 {
  width: 100% !important;
}

h1 {
  margin: 0 auto;
  font-size: 1.1rem;
}

.font_normal {
  font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
    sans-serif;
}

p {
  text-align: left;
  font-size: 0.85rem;
  line-height: 170%;
  &.small {
    margin-top: 20px !important;
    font-size: 0.75rem !important;
  }
  &.medium {
    font-size: 1rem !important;
  }
  &.big {
    font-size: 1.1rem !important;
  }
  &.center {
    text-align: center !important;
  }
}

// フォーム
.form_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @include mq-for-wideScreen-up {
    width: 65%;
    flex-wrap: nowrap;
  }
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px auto;
  text-align: left;
  &:last-of-type {
    margin-bottom: 20px;
  }
  label {
    width: 100%;
    padding: 12px 0;
    @include mq-for-wideScreen-up {
      width: 140px;
    }
  }
  input,
  select,
  .birthdateselect_wrap {
    width: 100% !important;
    @include mq-for-wideScreen-up {
      flex: 1;
      width: calc(100% - 140px) !important;
    }
  }
  textarea {
    width: 100% !important;
    @include mq-for-wideScreen-up {
      width: calc(100% - 140px) !important;
    }
  }
  .birthdateselect_wrap {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    label {
      width: auto;
      padding: 0 6px;
    }
    select {
      width: 70px !important;
    }
    .select:first-child {
      select {
        width: 90px !important;
      }
    }
  }
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="tel"],
textarea,
select {
  width: 100%;
  height: 42px;
  padding: 6px 12px;
  background-color: #ffffff;
  border-radius: 6px;
  font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
    sans-serif;
  color: #000000;
}

textarea {
  height: 252px;
}

input[type="date"] {
  position: relative;
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &::-webkit-clear-button {
    -webkit-appearance: none;
  }
}

.select {
  position: relative;
  display: inline-block;
  &:before {
    position: absolute;
    top: 0.9em;
    right: 0.6em;
    padding: 0;
    content: "";
    width: 7px;
    height: 7px;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
  }
  select {
    width: 100% !important;
    padding-right: 30px;
  }
}

// ボタン
.btn_link {
  margin-left: 10px;
  padding: 6px 20px 4px;
  border: solid 1px #00ffff;
  border-radius: 30px;
  text-align: center;
  color: #00ffff;
  font-size: 0.8rem;
  line-height: 100%;
  &.big {
    padding: 6px 20px;
    font-size: 1.1rem;
  }
  &:hover {
    opacity: 0.7;
  }
}

.btn_gradient {
  margin: 20px auto;
  padding: 12px 30px;
  background-image: linear-gradient(
    180deg,
    rgba(233, 67, 245, 1) 60%,
    rgba(62, 252, 255, 1) 100%
  );
  border-radius: 30px;
  font-size: 1.1rem;
  color: #ffffff;
  letter-spacing: 0.3em;
  text-indent: 1em;
  line-height: 130%;
  transition: filter 0.1s ease;
  filter: brightness(1);
  @include mq-for-wideScreen-up {
    letter-spacing: 1em;
  }
  &:hover {
    filter: brightness(1.2);
  }
  &.non_spacing {
    letter-spacing: 0;
    text-indent: 0;
  }
  &.big {
    font-size: 1.7rem;
  }
}

.btn_gradient_02 {
  margin: 20px auto;
  padding: 12px 30px;
  background-image: linear-gradient(
    180deg,
    rgba(233, 67, 245, 1) 10%,
    rgba(62, 252, 255, 1) 100%
  );
  border-radius: 30px;
  font-size: 1.3rem;
  color: #ffffff;
  letter-spacing: 0.5em;
  text-indent: 1em;
  line-height: 130%;
  text-shadow:
    1px 1px 3px #0b1728,
    -1px -1px 3px #0b1728;
  transition: filter 0.1s ease;
  filter: brightness(1);
  &:hover {
    filter: brightness(1.2);
  }
  &.non_spacing {
    letter-spacing: 0;
    text-indent: 0;
  }
}

.btn_important {
  padding: 6px 20px;
  border: solid 1px #ec3ff5;
  border-radius: 30px;
  background-color: #ec3ff5;
  box-shadow: 0px 0px 4px 2px rgba(236, 63, 245, 1);
  text-align: center;
  color: #0b1728;
  font-size: 1.1rem;
  letter-spacing: 0.4em;
  text-indent: 0.4em;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    cursor: default !important;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    &:hover {
      opacity: 1 !important;
    }
  }
}

.btn_secondary {
  padding: 6px 20px;
  border: solid 1px #ec3ff5;
  border-radius: 30px;
  text-align: center;
  color: #ec3ff5;
  font-size: 1.1rem;
  &:hover {
    opacity: 0.7;
  }
}

.btn_share_twitter {
  margin: 5px 0;
  padding: 12px 20px;
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  line-height: 100%;
  filter: brightness(1);
  &:hover {
    filter: brightness(1.2);
  }
}

.btn_share_image {
  margin: 5px 0;
  padding: 12px 20px;
  background-color: #ec3ff5;
  border-radius: 6px;
  color: #fff;
  line-height: 100%;
  filter: brightness(1);
  &:hover {
    filter: brightness(1.2);
  }
}

.btn_lp_login {
  display: block;
  width: 80%;
  max-width: 400px;
  margin: 10px auto;
  transition: filter 0.1s ease;
  filter: brightness(1);
  &:hover {
    filter: brightness(1.2);
  }
  img {
    width: 100%;
  }
}

.btn_iconfont {
  margin-right: 4px;
  font-size: 1.1rem;
  line-height: 100%;
  vertical-align: text-top;
}

.btn_loading {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100px;
  height: 30px;
  text-align: center;
  overflow: hidden;
  .loader {
    font-size: 0.3rem;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: loadanimation_w 1.1s infinite ease;
    animation: loadanimation_w 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.btn_userprofile {
  margin: 30px auto 0;
  width: 80%;
  @include mq-for-wideScreen-up {
    width: 400px;
  }
}

.ticket_wrap {
  display: flex;
  justify-content: space-between;
  .ticket {
    position: relative;
    width: 100%;
    margin: 0 0 0 2vw;
    padding: 20px;
    @include mq-for-wideScreen-up {
      margin: 0 0 0 20px;
      padding: 30px 35px;
    }
    border: solid 2px #ec3ff5;
    border-radius: 12px;
    &:first-child {
      margin: 0;
    }
    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      background-color: #0b1728;
    }
    &::before {
      top: -3px;
      bottom: -3px;
      left: 35px;
      right: 35px;
    }
    &::after {
      top: 28px;
      bottom: 28px;
      left: -3px;
      right: -3px;
    }
    .ticket_inr {
      position: relative;
      text-align: center;
      line-height: 180%;
      z-index: 10;
    }
  }
}

.fortuneForm {
  position: relative;
  margin: 30px auto;
  scroll-padding-top: $headerH;
  @include mq-for-wideScreen-up {
    scroll-padding-top: $headerH_pc;
  }
  p {
    padding: 20px 0;
    text-align: center;
    font-size: 1rem;
    &.remark {
      padding-top: 0;
      font-size: 0.85rem;
    }
  }
  .radio_wrap {
    padding: 0 0 30px;
  }
  .example {
    margin: 10px 0 20px;
    text-align: left;
    span {
      font-size: 0.85rem;
      line-height: 160%;
      &.head {
        margin-right: 10px;
        padding: 3px 10px;
        border-radius: 6px;
        &.category_love {
          background-color: #ec3ff5;
        }
        &.category_work {
          background-color: #00ffff;
        }
        &.category_money {
          background-color: #fff100;
        }
        &.category_other {
          background-color: #fff;
        }
      }
    }
    button {
      display: block;
      margin-left: auto;
      text-align: right;
      font-size: 0.85rem;
      text-decoration: underline;
      line-height: 150%;
      @include mq-for-wideScreen-up {
        margin-bottom: 0;
      }
      &:hover,
      &:active {
        text-decoration: none;
        opacity: 0.7;
      }
    }
    &.category_love {
      span {
        color: #ec3ff5;
        &.head {
          background-color: #ec3ff5;
          color: #000;
        }
      }
    }
    &.category_work {
      span {
        color: #00ffff;
        &.head {
          background-color: #00ffff;
          color: #000;
        }
      }
    }
    &.category_money {
      span {
        color: #fff100;
        &.head {
          background-color: #fff100;
          color: #000;
        }
      }
    }
    &.category_other {
      span {
        color: #fff;
        &.head {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }
  textarea {
    width: 100%;
    height: 100px;
    padding: 20px 25px;
    background-color: #ffffff;
    border-radius: 12px;
    color: #000000;
  }
}

.movie_loading {
  position: relative;
  width: 100%;
  margin: 50px auto;
  scroll-padding-top: $headerH;
  @include mq-for-wideScreen-up {
    scroll-padding-top: $headerH_pc;
  }
  animation: fadeIn 1s cubic-bezier(0.33, 1, 0.68, 1) 1 forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .movie_inr {
    position: relative;
    video {
      width: 100%;
      height: 100%;
    }
    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
      font-size: 1.7rem;
      color: #ffffff;
      @include mq-for-wideScreen-up {
        font-size: 2.2rem;
      }
    }
  }
  .remark {
    margin-top: 10px;
    text-align: center;
    font-size: 0.9rem;
    @include mq-for-wideScreen-up {
      font-size: 1rem;
    }
  }
}

.radio_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 20px 0;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio_label {
      border-radius: 30px;
      margin: 0 1vw;
      @include mq-for-wideScreen-up {
        margin: 0 10px;
      }
      padding: 12px 18px;
      letter-spacing: 0.4em;
      cursor: pointer;
      @media (max-width: 370px) {
        padding: 12px;
        letter-spacing: 0.2em;
      }
      &:before {
        content: "";
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 0.5em;
        @include mq-for-wideScreen-up {
          margin-right: 10px;
        }
        border-radius: 100%;
        vertical-align: text-top;
        top: 0;
        cursor: pointer;
        text-align: center;
        box-sizing: border-box;
      }
      &.category_love {
        border: solid 1px #ec3ff5;
        color: #ec3ff5;
        &:before {
          border: 1px solid #ec3ff5;
        }
      }
      &.category_work {
        border: solid 1px #00ffff;
        color: #00ffff;
        &:before {
          border: 1px solid #00ffff;
        }
      }
      &.category_money {
        border: solid 1px #fff100;
        color: #fff100;
        &:before {
          border: 1px solid #fff100;
        }
      }
      &.category_other {
        margin: 10px auto 0;
        border: solid 1px #fff;
        color: #fff;
        letter-spacing: 0;
        @include mq-for-wideScreen-up {
          margin: 0 10px;
        }
        &:before {
          margin-right: 0.3em;
          border: 1px solid #fff;
          @include mq-for-wideScreen-up {
            margin-right: 7px;
          }
        }
      }
    }
    &:checked,
    &:focus,
    &:hover {
      + .radio_label {
        &:before {
          border: 3px solid #0b1728;
        }
        &.category_love {
          background-color: #ec3ff5;
          box-shadow: 0px 0px 4px 2px rgba(236, 63, 245, 1);
          color: #0b1728;
        }
        &.category_work {
          background-color: #00ffff;
          box-shadow: 0px 0px 4px 2px rgba(0, 255, 255, 1);
          color: #0b1728;
        }
        &.category_money {
          background-color: #fff100;
          box-shadow: 0px 0px 4px 2px rgba(255, 241, 0, 1);
          color: #0b1728;
        }
        &.category_other {
          background-color: #fff;
          box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 1);
          color: #0b1728;
        }
      }
    }
  }
}

.answer_wrap {
  width: 100%;
  margin: 30px 0;
  scroll-padding-top: $headerH;
  @include mq-for-wideScreen-up {
    scroll-padding-top: $headerH_pc;
  }
  text-align: center;
  p {
    padding: 20px 0;
    text-align: center;
    font-size: 1rem;
  }
  .answer {
    height: 100%;
    padding: 40px 45px;
    background-image: url("../../public/images/frame_answer_t.png"),
      url("../../public/images/frame_answer_b.png"),
      url("../../public/images/frame_answer_m.png");
    background-repeat: no-repeat, no-repeat, repeat-y;
    background-size: 100%, 100%, 100%;
    background-position: top, bottom, top;
    text-align: left;
    font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
      sans-serif;
    color: #ffffff;
    line-height: 150%;
  }
  .column_wrap {
    margin-top: 50px;
    padding: 30px 35px;
    background-image: url("../../public/images/frame_column_t.png"),
      url("../../public/images/frame_column_b.png"),
      url("../../public/images/frame_column_m.png");
    background-repeat: no-repeat, no-repeat, repeat-y;
    background-size: 100%, 100%, 100%;
    background-position: top, bottom, top;
    text-align: left;
    .caption {
      vertical-align: middle;
      font-size: 1.1rem;
      color: #32ffff;
      line-height: 150%;
      &::before {
        display: inline-block;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 10px;
        background-image: url("../../public/images/icon_arrow_cian.png");
        background-size: contain;
      }
    }
    p {
      margin-left: 22px;
      padding: 20px 0 0;
      text-align: left;
    }
  }
}

#capture {
  position: relative;
  width: 900px;
  max-height: 1200px;
  padding: 70px 80px 40px 80px;
  background-image: url("../../public/images/frame_answer_t.png"),
    url("../../public/images/frame_answer_b.png"),
    url("../../public/images/frame_answer_m.png");
  background-repeat: no-repeat, no-repeat, repeat-y;
  background-size:
    900px 33px,
    900px auto,
    900px 33px;
  background-position: top, bottom, top;
  text-align: left;
  font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
    sans-serif;
  color: #ffffff;
  .inr {
    position: relative;
    max-height: 1008px;
    overflow: hidden;
    &.is_hidden::after {
      content: "…";
      position: absolute;
      right: 7px;
      bottom: 0;
      background: #0b1728;
      font-size: 2rem;
      line-height: 100%;
    }
    p {
      padding: 0;
      text-align: left;
      font-size: 2rem;
      line-height: 150%;
    }
    .is_hidden {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
    }
  }
  .footer {
    margin-top: 10px;
    text-align: right;
    img {
      width: 90%;
      max-width: 450px;
    }
  }
}

.saiki_profile_wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-top: 80px;
  padding: 40px 15px 30px;
  background-color: #0b1728;
  border: solid 1px #32ffff;
  border-radius: 6px;
  @include mq-for-wideScreen-up {
    padding: 40px 30px 30px;
  }
  .caption {
    position: absolute;
    top: -1.3em;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 20px;
    margin: 0;
    background-color: #0b1728;
    border: solid 1px #32ffff;
    border-radius: 6px;
    font-size: 1.2rem;
    color: #32ffff;
    white-space: nowrap;
    @include mq-for-wideScreen-up {
      left: 39%;
      transform: 0;
      padding: 15px 30px;
    }
  }
  img {
    width: 25vw;
    max-width: 120px;
    @include mq-for-wideScreen-up {
      width: 17%;
      max-width: 120px;
    }
  }
  p {
    width: 75vw;
    padding-left: 10px;
    font-size: 0.85rem;
    @include mq-for-wideScreen-up {
      width: 83%;
      padding-left: 30px;
    }
  }
  a {
    color: #32ffff;
    &:hover {
      opacity: 0.7;
    }
  }
}

.content_wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  .caption {
    text-align: left;
    font-size: 1.1rem;
    line-height: 180%;
  }
  .content_inr {
    width: 100%;
    margin-top: 20px;
  }
  p {
    font-size: 1rem;
    &.document {
      font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
        sans-serif;
      font-size: 0.9rem;
    }
  }
  dl {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    font-size: 1rem;
    @include mq-for-wideScreen-up {
      width: auto;
      justify-content: flex-start;
    }
    dt {
      width: 30%;
      max-width: 150px;
      margin: 5px 0;
      vertical-align: middle;
      line-height: 150%;
    }
    dd {
      width: 70%;
      max-width: 550px;
      margin: 5px 0;
      padding-left: 20px;
      line-height: 150%;
    }
    &.document {
      display: block;
      font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
        sans-serif;
      font-size: 0.9rem;
      dt,
      dd {
        width: 100%;
        max-width: none;
      }
      dt {
        margin-top: 20px;
      }
      dd {
        padding-left: 14px;
      }
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .radio_wrap {
    padding: 0;
  }
  .answer_wrap {
    margin: 5px auto;
    .info_wrap {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      margin: 0 auto 20px;
      @include mq-for-wideScreen-up {
        flex-direction: row;
      }
      .btn_wrap {
        max-width: 360px;
        margin: 10px 0 0 auto;
      }
      .date {
        margin-left: auto;
        padding: 10px 0 0;
        text-align: right;
        font-size: 0.75rem;
      }
    }
  }
  .btn_link {
    margin-left: auto;
  }
}

.btn_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 20px auto 30px;
}

.ticket_plan {
  position: relative;
  width: 100%;
  margin: 10px 0;
  padding: 20px;
  @include mq-for-wideScreen-up {
    padding: 30px 35px;
  }
  border: solid 2px #ec3ff5;
  border-radius: 12px;
  text-align: center;
  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    background-color: #0b1728;
  }
  &::before {
    top: -3px;
    bottom: -3px;
    left: 35px;
    right: 35px;
  }
  &::after {
    top: 28px;
    bottom: 28px;
    left: -3px;
    right: -3px;
  }
  .ticket_inr {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @include mq-for-wideScreen-up {
      flex-direction: row;
    }
    z-index: 10;
    .text_wrap {
      width: 100%;
      @include mq-for-wideScreen-up {
        width: auto;
      }
    }
    p {
      padding: 0;
      font-size: 1.1rem;
      line-height: 150%;
      &.remark {
        margin-top: 10px !important;
        font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
          sans-serif;
        font-size: 0.75rem;
      }
      &.price {
        margin-left: auto;
        text-align: right;
        font-size: 2rem;
        span {
          padding-right: 6px;
          font-size: 1rem;
        }
      }
    }
    .btn_important {
      display: inline-block;
      margin-top: 10px;
      margin-left: 0;
      @include mq-for-wideScreen-up {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
}

.payment_wrap {
  margin: 30px auto;
  text-align: center;
  form {
    width: 100%;
  }
  .payment_form {
    width: 100%;
    height: 44px;
    padding: 12px;
    background-color: #fff;
    border-radius: 6px;
  }
  .btn_link,
  .btn_loading {
    width: 80px;
    margin: 10px 0;
    @include mq-for-wideScreen-up {
      margin: 4px 0 0 20px;
    }
  }
  img {
    display: block;
    margin: 10px auto;
    width: 100%;
    max-width: 120px;
  }
  img.credit {
    width: 100%;
    max-width: 330px;
  }
  p.note {
    margin-top: 10px;
    text-align: center;
    font-size: 0.8rem;
  }
}

.lp_trial_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px auto;
  padding: 6px;
  border: solid 2px #ec3ff5;
  border-radius: 12px;
  text-align: center;
  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    background-color: #0b1728;
  }
  &::before {
    top: -3px;
    bottom: -3px;
    left: 35px;
    right: 35px;
  }
  &::after {
    top: 28px;
    bottom: 28px;
    left: -3px;
    right: -3px;
  }
  .lp_trial_inr {
    position: relative;
    width: 100%;
    z-index: 10;
    .caption {
      width: 100%;
      padding: 15px 10px;
      margin: 0;
      background-color: #ec3ff5;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      font-size: 1.3rem;
      color: #fff;
      white-space: nowrap;
      @include mq-for-wideScreen-up {
        padding: 15px 30px;
        font-size: 1.5rem;
      }
    }
    .theme {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 15px 25px;
      background-color: #fff;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      color: #ec3ff5;
      p {
        text-align: center;
        font-size: 1.3rem;
        &.sub {
          color: #000;
          font-size: 1rem;
        }
      }
    }
    .lp_trial_loading {
      .loading {
        height: 100px;
        margin: 30px auto;
      }
      .movie_inr {
        p {
          position: relative;
          padding-top: 30px;
        }
      }
    }
  }
}

#trial_capture {
  position: relative;
  width: 900px;
  max-height: 1200px;
  margin: 0;
  padding: 12px;
  border: none;
  border-radius: 0;
  background: #0b1728;
  color: #ffffff;
  &::before,
  &::after {
    display: none;
  }
  .lp_trial_inr {
    font-size: 2rem;
    .caption {
      width: 100%;
      padding: 15px 10px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      font-size: 2.3rem;
      color: #fff;
      white-space: nowrap;
    }
    .theme {
      width: 100%;
      margin: 0 0 20px 0;
      padding: 15px 25px;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      p {
        font-size: 2.3rem;
        &.sub {
          font-size: 2rem;
        }
      }
    }
  }
  .answer_wrap {
    margin: 0;
    .answer {
      position: relative;
      max-height: 816px;
      padding: 20px 40px;
      overflow: hidden;
      background-image: none !important;
      &.is_hidden::after {
        content: "…";
        position: absolute;
        right: 7px;
        bottom: 0;
        background: #0b1728;
        font-size: 2rem;
        line-height: 100%;
      }
      p {
        padding: 0;
        text-align: left;
        font-size: 2rem;
        line-height: 150%;
      }
      .is_hidden {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
      }
    }
  }
  .footer {
    text-align: right;
    img {
      width: 90%;
      max-width: 450px;
    }
  }
}

.lp_step_wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 70px auto 50px;
  padding: 50px 20px 20px;
  border: solid 1px #32ffff;
  border-radius: 6px;
  text-align: center;
  @include mq-for-wideScreen-up {
    padding: 50px 30px 20px;
  }
  .caption {
    position: absolute;
    top: -1em;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px 10px;
    margin: 0 auto;
    background-color: #0b1728;
    border: solid 1px #32ffff;
    border-radius: 6px;
    font-size: 1.3rem;
    color: #32ffff;
    white-space: nowrap;
    @include mq-for-wideScreen-up {
      padding: 15px 30px;
      font-size: 1.8rem;
    }
  }
  ul {
    margin: 0 auto;
    list-style: none;
    li {
      width: 100%;
      position: relative;
      padding-bottom: 14px;
      text-align: left;
      white-space: nowrap;
      @include mq-for-wideScreen-up {
        font-size: 1.4rem;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: calc(50% - 5px);
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 5px 0 5px;
        border-color: #32ffff transparent transparent transparent;
      }
      &:last-of-type {
        &::after {
          border: none;
        }
      }
      p {
        display: inline-block;
        width: 35%;
        font-size: 1.1rem;
        color: #32ffff;
        span {
          margin-left: 4px;
          font-size: 1.4rem;
          @include mq-for-wideScreen-up {
            font-size: 2rem;
          }
        }
      }
      span.text {
        width: 65%;
        padding-bottom: 2px;
        border-bottom: solid 1px #ec3ff5;
        font-size: 1.2rem;
        color: #fff;
        @include mq-for-wideScreen-up {
          font-size: 1.4rem;
        }
      }
    }
  }
}

.lp_movie_wrap {
  position: relative;
  margin: 50px auto;
  video {
    width: auto;
    height: 100%;
    max-height: 600px;
    border: solid 2px #ec3ff4;
    border-radius: 8px;
  }
}

.lp_banner_txt {
  margin-top: 50px;
  text-align: center;
  font-size: 1.1rem;
  @include mq-for-wideScreen-up {
    font-size: 1.8rem;
  }
}

// モーダルウィンドウ
.ReactModal__Overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  z-index: 2000;
}
.ReactModal__Content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  right: auto !important;
  bottom: auto !important;
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  margin-right: -50% !important;
  padding: 30px !important;
  transform: translate(-50%, -50%) !important;
  border: none;
  border-radius: 12px !important;
  background-color: #fff;
  text-align: center;
  color: #000;
  overflow: hidden !important;
  z-index: 2010;
  &.exam_modal {
    padding: 40px 5px 30px 30px !important;
  }
  p {
    font-size: 1.2rem;
    &.remark {
      font-size: 1rem;
    }
  }
  img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: calc(85vh - 162px);
  }
  .ticket_plan {
    &::before,
    &::after {
      background-color: #fff;
    }
  }
  .exam {
    @extend .font_normal;
    height: 100%;
    max-height: calc(85vh - 60px);
    overflow-y: auto;
    padding-right: 25px;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track-piece {
      background: #fff;
    }
    .head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.1rem;
      font-weight: bold;
      span {
        text-align: left;
        &:first-of-type {
          flex-shrink: 0;
        }
      }
      svg {
        font-size: 1.3rem;
        fill: #f29600;
        &:first-child {
          font-size: 2.4rem;
          padding-right: 8px;
        }
      }
    }
    .exam_inr {
      position: relative;
      margin: 40px 0 0;
      padding: 10px 20px;
      font-size: 0.9rem;
      .head_small {
        position: absolute;
        top: -23px;
        left: 15px;
        padding: 5px 12px 3px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        text-align: center;
        color: #fff;
        font-weight: bold;
      }
      ul {
        list-style: none;
        li {
          padding: 10px 0;
          text-align: left;
          line-height: 150%;
          &:last-child {
            border-bottom: none;
          }
        }
      }
      &.category_love {
        margin-top: 30px;
        border: solid 1px #e4007f;
        .head_small {
          background-color: #e4007f;
        }
        li {
          border-bottom: dotted 1px #e4007f;
        }
      }
      &.category_work {
        border: solid 1px #2ea7e0;
        .head_small {
          background-color: #2ea7e0;
        }
        li {
          border-bottom: dotted 1px #2ea7e0;
        }
      }
      &.category_money {
        border: solid 1px #f8b62d;
        .head_small {
          background-color: #f8b62d;
        }
        li {
          border-bottom: dotted 1px #f8b62d;
        }
      }
      &.category_other {
        border: solid 1px #898989;
        .head_small {
          background-color: #898989;
        }
        li {
          border-bottom: dotted 1px #898989;
        }
      }
    }
    p {
      margin: 6px 0;
      font-size: 0.9rem;
    }
  }
  .btn_wrap {
    margin: 10px auto;
  }
  .btn_close {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      font-size: 2rem;
      color: #000;
    }
  }
  .loading {
    height: 55px;
    margin: 10px 0;
    .loader {
      font-size: 0.5rem;
      -webkit-animation: loadanimation_b 1.1s infinite ease;
      animation: loadanimation_b 1.1s infinite ease;
    }
  }
}

.lp_campaign_banner {
  width: 100%;
  margin: 20px auto 0;
}

.lp {
  .frame-frame101 {
    gap: 40px;
    display: flex;
    padding: 40px 10px 0;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    background-color: rgba(11, 23, 40, 1);
  }
  .frame-frame113 {
    gap: 20px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame-text074 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 2.2rem;
    text-align: center;

    font-weight: 400;
    line-height: 140%;
    font-stretch: normal;
    text-decoration: none;
    text-shadow:
      0px 0px 0px #ec3ff5,
      0px 0px 10px #ec3ff5,
      0px 0px 15px #ec3ff5,
      0px 0px 20px #ec3ff5;
  }
  .frame-frame29 {
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
  }
  .frame-text079 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 1.5rem;
    text-align: center;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame30 {
    gap: 16px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .frame-frame31 {
    gap: 10px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 50px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .frame-frame11 {
    gap: 10px;
    display: flex;
    padding: 0 8px;
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
  }
  .frame-group2 {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame-frame32 {
    gap: 10px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    border-color: rgba(50, 255, 255, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text081 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    flex-grow: 1;
    font-size: 1.2rem;
    align-self: stretch;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame321 {
    gap: 16px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .frame-frame322 {
    gap: 10px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    border-color: rgba(236, 63, 245, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text086 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    flex-grow: 1;
    font-size: 1.1rem;
    align-self: stretch;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame311 {
    gap: 10px;
    display: flex;
    padding: 10px;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 50px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .frame-frame12 {
    gap: 10px;
    display: flex;
    padding: 0 8px;
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
  }
  .frame-group3 {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame-frame312 {
    gap: 16px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .frame-frame313 {
    gap: 10px;
    display: flex;
    padding: 10px;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 50px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .frame-frame13 {
    gap: 10px;
    display: flex;
    padding: 0 8px;
    overflow: hidden;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
  }
  .frame-group4 {
    display: flex;
    position: relative;
    width: 34px;
    height: 50px;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .frame-vector6 {
    top: 0px;
    left: 3.8427743911743164px;
    width: 26px;
    height: 26px;
    position: absolute;
  }
  .frame-vector7 {
    top: 27.515241622924805px;
    left: 0px;
    width: 34px;
    height: 22px;
    position: absolute;
  }
  .frame-frame323 {
    gap: 10px;
    display: flex;
    padding: 24px;
    overflow: hidden;
    flex-grow: 1;
    align-items: flex-start;
    border-color: rgba(50, 255, 255, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text091 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    flex-grow: 1;
    font-size: 1.1rem;
    align-self: stretch;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame102 {
    gap: 40px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
  }
  .frame-frame103 {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .frame-text096 {
    color: rgba(255, 255, 255, 1);
    font-size: 2rem;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame27 {
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
  }
  .frame-frame33 {
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: url("../../public/images/campaign/bg_uranai1-500h.png");
  }
  .frame-frame34 {
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
  }
  .frame-text098 {
    color: rgba(255, 255, 255, 1);
    flex-grow: 1;
    font-size: 2.5rem;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
    text-shadow:
      0px 0px 0px #ec3ff5,
      0px 0px 10px #ec3ff5,
      0px 0px 15px #ec3ff5,
      0px 0px 20px #ec3ff5;
  }
  .frame-frame36 {
    gap: 10px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame-frame35 {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .frame-dalle202310231423041 {
    box-shadow: 15px 15px 0px -5px #ec3ff5;
    border-radius: 0px;
  }
  .frame-frame122 {
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame-text103 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 2.8rem;
    text-align: center;

    font-weight: 400;
    line-height: 150%;
    font-stretch: normal;
    text-decoration: none;
    text-shadow:
      0px 0px 0px #ec3ff5,
      0px 0px 10px #ec3ff5,
      0px 0px 15px #ec3ff5,
      0px 0px 20px #ec3ff5;
  }
  .frame-frame291 {
    gap: 10px;
    display: flex;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .frame-frame301 {
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
  }
  .frame-frame314 {
    gap: 7px;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-direction: column;
  }
  .frame-text105 {
    color: rgba(236, 63, 245, 1);
    height: auto;
    font-size: 1.5rem;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame324 {
    gap: 10px;
    display: flex;
    padding: 24px;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(236, 63, 245, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text107 {
    color: rgba(255, 255, 255, 1);
    flex-grow: 1;
    align-self: stretch;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-text114 {
    color: rgba(50, 255, 255, 1);
    font-size: 1.2rem;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame325 {
    gap: 10px;
    display: flex;
    padding: 24px;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(50, 255, 255, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text123 {
    color: rgba(245, 202, 64, 1);
    font-size: 1.2rem;
    text-align: left;
    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame326 {
    gap: 10px;
    display: flex;
    padding: 24px;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(245, 202, 64, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
  .frame-text132 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 1.2rem;
    text-align: left;

    font-weight: 400;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .frame-frame327 {
    gap: 10px;
    display: flex;
    padding: 24px;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(255, 255, 255, 1);
    border-style: solid;
    border-width: 3px;
    border-radius: 8px;
  }
}

.lp_subtitle {
  margin: 60px auto 20px;
  font-size: 2.2rem;
}
.guideline {
  width: 100%;
  height: 300px;
  margin: 0 auto 40px auto;
  padding: 10px;
  overflow-y: scroll;
  border: solid 1px #ccc;
  border-radius: 6px;
  background-color: #fff;
  font-family: "Hiragino Sans", "Meiryo", "Hiragino Kaku Gothic ProN",
    sans-serif;
  font-size: 80%;
  line-height: 150%;
  text-align: left;
  color: #000;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track-piece {
    background: #fff;
    border-radius: 6px;
  }
  p {
    word-wrap: break-word;
  }
  a {
    text-decoration: underline;
  }
}
